.sec-2 {
  width: 100%;
  height: 100vh;
  background: #394149;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  gap: 300px;
  overflow: hidden;
}
.block3 {
  width: 400.248px;
  height: 400.383px;
  flex-shrink: 0;
  border-radius: 20px 215.5px 255.191px 255.191px;
}
.imagecontent {
  width: 460px;
  height: 460px;
  flex-shrink: 0;
  padding: 20px;
  border-radius: 0px 215.5px 255.191px 255.191px;
  background: rgba(0, 212, 200, 0.43);
  filter: blur(139.5px);
}
.block3 img {
  width: 326.689px;
  height: 491.158px;
  flex-shrink: 0;
  top: -120%;
  left: 100px;
  position: relative;
}
.block4 {
  width: 600px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.block4 h1 {
  display: flex;
  width: 386px;
  height: 85px;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.block4 p {
  width: fit-content;
  height: fit-content;
  display: flex;
  color: #fff;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-overflow: clip;
  line-height: 150%; /* 22.58px */
}
.socialcontent {
  gap: 150px;
  display: flex;
  flex-direction: row;
}
.info {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.socialmedia {
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialmedia a {
  display: flex;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  letter-spacing: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 112.9%; /* 15.806px */
  cursor: pointer;
}
.info a {
  display: flex;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  letter-spacing: 2px;
  font-size: 14px;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  line-height: 112.9%; /* 15.806px */
  cursor: pointer;
}
.block4 button {
  width: 250px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.33);
  outline: none;
  border: none;
  color: #fff;
  text-align: center;
  font-family: texgy;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%; /* 32.976px */
  cursor: pointer;
  top: 50px;
  position: relative;
  transition: 0.8s;
}
.block4 button:hover {
  font-size: 30px;
  border-radius: 15px;
}
.block4 img {
  position: relative;
  left: -40%;
  top: -20%;
}
@media only screen and (max-width: 1500px) {
  .sec-2 {
    gap: 200px;
  }
}
@media only screen and (max-width: 1250px) {
  .sec-2 {
    flex-direction: column-reverse;
    height: fit-content;
    padding: 200px;
  }
}
@media only screen and (max-width: 680px) {
  .sec-2 {
    padding: 0;
    height: fit-content;
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .block4 {
    gap: 20px;
    width: 100%;
  }
  .block4 p {
    font-size: 15px;
    text-align: center;
    display: flex;
    height: fit-content;
    width: 95%;
  }
  .socialcontent {
    gap: 50px;
    flex-direction: column;
  }
  .block3 img {
    left: 50px;
  }
  .block4 img {
    top: -65px;
  }
}
@media only screen and (max-width: 525px) {
  .sec-2 {
    gap: 50px;
  }
  .block4 img {
    top: -160px;
    left: -160px;
    rotate: 40deg;
  }
}
@media only screen and (max-width: 460px) {
  .block4 img {
    left: -140px;
  }
  .block4 p {
    font-weight: bold;
  }
  .block3 img {
    width: 250px;
    height: auto;
    display: flex;
  }
  .block3 {
    width: 350px;
    height: 350px;
  }
}
