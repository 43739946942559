.sec-5 {
  width: 100%;
  height: 700px;
  background: linear-gradient(
    180deg,
    rgba(0, 212, 200, 0.78) 50%,
    rgba(0, 0, 0, 0.78) 100%
  );
  overflow: hidden;
  display: flex;
  padding: 50px;
  align-items: center;
  flex-direction: column;
}
.sec-5 h1 {
  color: #fff;
  font-size: 45px;
}
.contactinputs {
  width: 700px;
  height: fit-content;
  margin-top: 50px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.contactinputs input {
  width: 300px;
  height: 35px;
  border-radius: 30px;
  background: #fff;
  border: none;
  padding: 15px;
  text-align: end;
}
.contactinputs textarea {
  display: flex;
  width: 350px;
  height: 100px;
  border-radius: 30px 30px 10px 30px;
  background: #fff;
  padding: 15px;
  text-align: end;
  font-family: texgy;
  border: none;
}
.sec-5 button {
  color: #fff;
  text-align: center;
  font-family: texgy;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-top: 50px;
  line-height: 137.4%; /* 32.976px */
  display: flex;
  width: 250px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 30px;
  background: rgba(1, 255, 242, 0.33);
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  transition: 0.8s;
  cursor: pointer;
}
.sec-5 button:hover {
  font-size: 30px;
  border-radius: 15px;
}
