body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: texgy;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  overflow-x: hidden;
  scroll-behavior: smooth;
  animation: fadeIn 3s;
}

code {
  font-family: texgy;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@font-face {
  font-family: texgy;
  src: url("../src/fonts/Arimo-VariableFont_wght.ttf");
}
@font-face {
  font-family: konxy;
  src: url("../src/fonts/KonexyPersonalUse.otf");
}
