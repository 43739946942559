.sec-1 {
  background: linear-gradient(
    90deg,
    #000 38.54%,
    rgba(0, 212, 200, 0.79) 98.67%
  );
  width: 100%;
  height: 92.8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.block1 {
  display: flex;
  justify-content: center;
  width: 50%;
  align-items: flex-start;
  flex-direction: column;
}
.block1 h1 {
  color: #fff;
  font-family: konxy;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.block1 p {
  color: #fff;
  font-family: konxy;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%; /* 32.976px */
  width: 684px;
  height: 156px;
  flex-shrink: 0;
}
.block2 {
  width: 500px;
  height: 151px;
  flex-shrink: 0;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.19);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.div2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.div2 button {
  width: 250px;
  height: 70px;
  flex-shrink: 0;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.33);
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: texgy;
  letter-spacing: 1px;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%; /* 32.976px */
  transition: 0.8s;
}
.div2 button:hover {
  font-size: 35px;
}
.block2 p {
  background: linear-gradient(90deg, #00ffd1 35.4%, #ffffff 76.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: TeX Gyre Adventor;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.block2 button {
  width: 200px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #04b4a9;
  color: #fff;
  text-align: center;
  font-family: texgy;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: 1s;
  border: none;
}
.block2 button:hover {
  font-size: 30px;
}
@media only screen and (max-width: 1420px) {
  .sec-1 {
    gap: 200px;
  }
  .block1 {
    width: fit-content;
  }
  .block1 h1 {
    font-size: 45px;
  }
  .block1 p {
    font-size: 20px;
    width: 450px;
  }
}
@media only screen and (max-width: 1190px) {
  .sec-1 {
    gap: 150px;
  }
  .block2 {
    width: 350px;
    height: 100px;
  }
  .block2 img {
    width: 60px;
  }
  .div2 button {
    width: 200px;
    height: 50px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  header {
    background: #000;
  }
  .sec-1 {
    flex-direction: column;
    gap: 150px;
    background: linear-gradient(
      180deg,
      #000 38.54%,
      rgba(0, 212, 200, 0.79) 98.67%
    );
  }
  .block1 {
    gap: 20px;
  }
  .block1 h1 {
    font-size: 50px;
  }
  .block1 p {
    text-align: center;
  }
}
@media only screen and (max-width: 520px) {
  .sec-1 {
    flex-direction: column;
    gap: 100px;
  }
  .block1 {
    width: 95%;
    height: fit-content;
  }
  .block1 h1 {
    text-align: center;
    font-size: 40px;
  }
  .block1 p {
    width: 400px;
    text-align: left;
    font-size: 16px;
  }
}
@media only screen and (max-width: 460px) {
  .sec-1 {
    height: 92.2vh;
    gap: 100px;
  }
  .block1 {
    padding: 20px;
    gap: 15px;
  }
  .block1 h1 {
    font-size: 30px;
  }
  .block1 p {
    width: 95%;
    font-size: 16px;
  }
  .div2 button {
    width: 200px;
    height: 50px;
    font-size: 25px;
  }
}
