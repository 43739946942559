.sec-4 {
  width: 100%;
  height: fit-content;
  background: #000;
  padding-bottom: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.head {
  width: 100%;
  height: 100px;
  background: #00d4c8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head h1 {
  color: #fff;
  font-size: 45px;
}
.icons {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 50px;
}
.icons img {
  width: 80px;
}
.sec-4 p {
  width: fit-content;
  padding: 20px;
  height: fit-content;
  text-overflow: clip;
  color: #fff;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.sec-4 .arrow2 {
  width: 100px;
  height: auto;
  margin-top: 50px;
  transform: rotate(-30.381deg);
}
