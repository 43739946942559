.sec-6 {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  gap: 50px;
  flex-wrap: wrap;
}
.sec-6 h1 {
  color: #fff;
  font-size: 30px;
  letter-spacing: 1px;
}
.sec-6 p {
  color: #fff;
  font-size: 10px;
}
