.sec-3 {
  width: 100%;
  height: fit-content;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  overflow: hidden;
  padding: 50px;
}
.sec-3 h1 {
  color: #000000;
  text-align: center;
  font-family: texgy;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%; /* 65.952px */
  text-decoration: underline;
}
.procon div {
  width: 350px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #000000;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.procon {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  flex-wrap: wrap;
}
.procon div h1 {
  font-size: 30px;
  color: #fff;
  text-decoration: none;
}
.procon div img {
  width: 150px;
  height: auto;
}
.procon div a {
  color: #fff;
  text-align: center;
  font-family: texgy;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%; /* 32.976px */
  display: flex;
  width: 250px;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 30px;
  background: rgba(1, 255, 242, 0.33);
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  transition: 0.8s;
  cursor: pointer;
}
.procon div a:hover {
  font-size: 30px;
  border-radius: 15px;
}
